import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

const information = [
  {
    id: '1',
    label: 'COMMON.CREDITO.CAT_PROMEDIO.LABEL',
    description: 'COMMON.CREDITO.CAT_PROMEDIO.DESCRIPTION',
  },
  {
    id: '2',
    label: 'COMMON.CREDITO.YEARLY_RATE.LABEL',
    description: 'COMMON.CREDITO.YEARLY_RATE.DESCRIPTION',
  },
  {
    id: '3',
    label: 'COMMON.CREDITO.ANNUAL_FEE.LABEL',
    description: 'COMMON.CREDITO.ANNUAL_FEE.DESCRIPTION',
  },
];

const CreditoRates = () => {
  const { formatMessage } = useIntl();
  const notMobile = useBreakpointsMediaUp('md') ? 'heading4' : 'heading3';

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'flex-start' }}
      alignItems={{ xs: 'flex-start' }}
    >
      {information.map(el => (
        <Box
          key={el.id}
          marginVertical={{ xs: '6x' }}
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: 'flex-start' }}
          alignItems={{ xs: 'flex-start' }}
        >
          {el.label && (
          <Typography
            variant="heading4"
            textAlign={{ xs: 'left' }}
          >
            {`${formatMessage({ id: el.label })}`}
          </Typography>
          )}

          <Typography
            variant={notMobile}
            tag="span"
            color="primary"
            textAlign={{ xs: 'left' }}
          >
            {`${formatMessage({ id: el.description })}`}
          </Typography>

          {el.disclaimer && (
          <Typography
            variant="paragraph1"
            whiteSpace="pre-line"
            marginTop="4x"
            marginBottom="8x"
            width={{ xs: '95%', md: '90%', lg: '100%' }}
            textAlign={{ xs: 'left', md: 'center' }}
          >
            {`${formatMessage({ id: el.disclaimer })}`}
          </Typography>
          )}
        </Box>
      ))}

      <Typography
        intlKey="COMMON.CREDITO.LEGAL.DISCLAIMER"
        variant="paragraph1"
        marginBottom="4x"
        width={{ xs: '95%', md: '90%', lg: '100%' }}
        textAlign={{ xs: 'left' }}
      />

    </Box>
  );
};

export default CreditoRates;
