import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';

/* How to use
  Use as usual Typography component, using {highlight} in the part of your string
  where you want the highlighting to be
  Additonal properties:
    - highlightColor : color of the text to be highlighted
    - highlightBackgroundColor : background color of the text to be highlighted
    - highlightText: text to be highlighted (STRING)
    - animate (Boolean)
  Example
    "HIGHLIGHT.EXAMPLE": "This {highlight} will be highlighted",
    "HIGHLIGHT.EXAMPLE.HIGHLIGHT": "text"
    <TypographyColorHighlight
      intlKey="HIGHLIGHT.EXAMPLE"
      variant="heading3"
      highlightText={formatMessage({ id: 'HIGHLIGHT.EXAMPLE.HIGHLIGHT' })}
      highlightColor="primary.default"
    />
*/

const TypographyColorHighlight = props => {
  const {
    highlightBackgroundColor,
    highlightBackgroundRadius,
    highlightText,
    highlightColor,
    intlValues,
    animate,
    backgroundPadding,
  } = props;

  const highlightIntlValuesObj = {
    highlight: (
      <Box
        tag="span"
        backgroundColor={highlightBackgroundColor}
        color={highlightColor}
        paddingHorizontal={highlightBackgroundColor ? backgroundPadding : '0px'}
        borderRadius={highlightBackgroundRadius}
      >
        {highlightText}
      </Box>
    ),
  };

  if (intlValues) {
    Object.keys(intlValues).map(el => {
      highlightIntlValuesObj[el] = intlValues[el];
      return highlightIntlValuesObj;
    });
  }

  if (animate) {
    const { ref } = useParallax({
      opacity: [0, 1],
      easing: [0, 0.9, 1, 1.4],
      scaleY: [0.8, 1, 'easeOutQuint'],
    });

    return (
      <Box ref={ref}>
        <Typography
          {...props}
          intlValues={highlightIntlValuesObj}
        />
      </Box>
    );
  }
  return (
    <Typography
      {...props}
      intlValues={highlightIntlValuesObj}
    />
  );
};

TypographyColorHighlight.defaultProps = {
  animate: false,
  backgroundPadding: '2x',
  highlightBackgroundColor: '',
  highlightBackgroundRadius: '0px',
  highlightColor: '',
  highlightText: '',
  intlValues: {},
};

TypographyColorHighlight.propTypes = {
  animate: PropTypes.bool,
  backgroundPadding: PropTypes.string,
  highlightBackgroundColor: PropTypes.string,
  highlightBackgroundRadius: PropTypes.string,
  highlightColor: PropTypes.string,
  highlightText: PropTypes.string,
  intlValues: PropTypes.objectOf(PropTypes.shape),
};

export default TypographyColorHighlight;
