import { useRef, useEffect, useState } from 'react';

const defaultValues = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

const useObserveElement = options => {
  const element = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const values = options || defaultValues;

  const observedEntries = entries => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observedEntries, values);
    if (element.current) observer.observe(element.current);

    return () => {
      if (element.current) observer.unobserve(element.current);
    };
  }, [element, values]);

  return [element, isVisible];
};

export default useObserveElement;
