import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled, { css } from 'styled-components';
import { useBreakpointsMediaUp, breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const StyledTypography = styled(Typography)`
  background: ${props => (props.color)};
  font-size: ${props => (props.fontSize)};
  /* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  ${breakpointsMedia({
    lg: css`
      font-size: ${props => (props.biggerSize ? props.fontSize : '22px')};
    `,
  })}
`;

const ProductTitle = props => {
  const { title, biggerSize } = props;
  const notMobile = useBreakpointsMediaUp('md') ? 'heading4' : 'heading3';
  const vertical = useBreakpointsMediaUp('lg') && !biggerSize;

  const titleColor = {
    cuenta: 'linear-gradient(99.92deg, #A376FF 18.57%, #7B4DD6 71.77%)',
    credito: 'linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%)',
    creditoNu: 'linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%)',
  };

  const productList = {
    cuenta: 'Cuenta Nu',
    credito: 'Tarjeta de Crédito',
    creditoNu: 'Tarjeta de Crédito Nu',
  };

  const images = {
    cuenta: 'components/rates/cuenta.svg',
    credito: 'components/rates/credito.svg',
    creditoNu: 'components/rates/credito.svg',
  };

  const product = productList[title] ? title : 'cuenta';

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', lg: vertical ? 'column' : 'row' }}
      justifyContent={{ xs: 'left' }}
      alignItems="center"
    >
      <Image
        alt="Icono de una tarjeta de cuenta"
        src={images[product]}
        width={biggerSize ? '40px' : '30px'}
        height={biggerSize ? '40px' : '30px'}
        webp={false}
      />
      <Box marginLeft={{ xs: '4x', lg: vertical ? '0x' : '4x' }}>
        <StyledTypography
          variant={notMobile}
          textAlign={{ xs: 'left', md: 'center', lg: 'left' }}
          color={titleColor[product]}
          fontSize={biggerSize ? '30px' : 'auto'}
          biggerSize={biggerSize}
        >
          {productList[product]}
        </StyledTypography>
      </Box>
    </Box>
  );
};

ProductTitle.defaultProps = {
  biggerSize: false,
  title: 'cuenta',
};

ProductTitle.propTypes = {
  biggerSize: PropTypes.bool,
  title: PropTypes.string,
};

export default ProductTitle;
