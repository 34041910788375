import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';

import ProductTitle from './ProductTitle';
import CuentaRates from './CuentaRates';
import CreditoRates from './CreditoRates';

const StyledCloseButton = styled(Box)`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

const StyledBox = styled(Box)`
  .fade-in {
    opacity: 0.1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in.active {
    opacity: 1;
  }
`;

const Line = styled(Box)`  
  border-left: 3px solid #1111111a;
  height:45%;
  left: 49%;
  margin-top: 110px;  
  position: absolute;
`;

function RatesModal({ closeModal, hideCredito, hideCuenta }) {
  const [active, setActive] = useState(false);
  const cuentaDefault = !!((hideCuenta && hideCredito));
  const hideAProduct = hideCredito || hideCuenta;
  const bothProducts = !hideAProduct && !cuentaDefault;

  useEffect(() => {
    // Trigger animation after a short delay to ensure proper rendering
    setTimeout(() => setActive(true), 10);
  }, []);

  const handleClose = () => {
    setActive(false);
    setTimeout(closeModal, 100);
  };

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <StyledBox
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems={{ xs: 'start', lg: 'center' }}
      paddingTop={{ xs: '40px', lg: '0' }}
      backgroundColor="rgba(0, 0, 0, 0.7)"
      zIndex="999"
      overflow="scroll"
      onClick={handleOverlayClick}
    >
      <StyledBox
        backgroundColor="white.default"
        paddingTop="30px"
        paddingBottom="30px"
        paddingRight="43px"
        paddingLeft="29px"
        borderRadius="12px"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.2)"
        position="relative"
        width={bothProducts ? '1000px' : '455px'}
        display="flex"
        flexDirection="column"
        alignItems={{ xs: 'left', md: 'center' }}
        margin={{ xs: '8x', md: '0' }}
        className={`fade-in ${active ? 'active' : ''}`}
      >
        <StyledCloseButton onClick={closeModal}>
          <Image
            alt="Cerrar"
            src="benefits/close-big.svg"
            webp={false}
            width="auto"
            importance="high"
          />
        </StyledCloseButton>

        {/* MODAL CONTENT STARTS */}
        {/* Cuenta */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom="4x"
          paddingHorizontal={bothProducts ? '8x' : '4x'}
          marginTop="8x"
        >
          <Box
            display={hideCuenta && !cuentaDefault ? 'none' : 'auto'}
            width={bothProducts ? '87%' : '100%'}
          >
            <ProductTitle biggerSize />
            <CuentaRates />
          </Box>

          {/* Separation Line */}
          {
            bothProducts
              ? (
                <Box width="26%">
                  <Line />
                </Box>
              ) : null
          }

          {/* Credito */}
          <Box
            display={hideCredito ? 'none' : 'auto'}
            width={bothProducts ? '87%' : '100%'}
          >
            <ProductTitle title="creditoNu" biggerSize />
            <CreditoRates />
          </Box>

        </Box>

        {/* MODAL CONTENT ENDS */}

      </StyledBox>
    </StyledBox>
  );
}

RatesModal.defaultProps = {
  hideCredito: false,
  hideCuenta: false,
};

RatesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hideCredito: PropTypes.bool,
  hideCuenta: PropTypes.bool,
};

export default RatesModal;
