import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { v4 as uuid } from 'uuid';

import useObserveElement from '../../utils/hooks/useObserveElement';

import { StyledBox } from './StyledBox';

const Counter = ({
  start, end, speed, incrementBy, color, fontSize, firstColor, firstFontSize, addSymbol, dotSize,
}) => {
  const [element, isVisible] = useObserveElement({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  const [count, setCount] = useState(start);

  const increment = useMemo(() => incrementBy, [end]);

  const formatNumber = num => Number(num.toFixed(1));

  const doIncrement = () => {
    if (isVisible) {
      if (count < end) {
        const timer = setTimeout(
          () => setCount(
            count < (end - increment)
              ? formatNumber(count + increment) : end,
          ),
          speed,
        );
        return () => clearTimeout(timer);
      }
    } else {
      setCount(start);
    }
    return count;
  };

  useEffect(doIncrement, [isVisible, count, end, increment]);

  return (
    <StyledBox
      ref={element}
      isVisible={isVisible}
      firstColor={firstColor}
      firstFontSize={firstFontSize}
      color={color}
      fontSize={fontSize}
      dotSize={dotSize}
    >
      {addSymbol ? <span> + </span> : null }
      {count.toString().split('').map(el => (
        <Typography key={el + uuid()}>
          {el}
        </Typography>
      ))}
    </StyledBox>
  );
};

Counter.defaultProps = {
  addSymbol: false,
  color: 'black',
  end: 0,
  firstColor: 'black',
  firstFontSize: '12px',
  fontSize: '12px',
  dotSize: '12px',
  incrementBy: 0,
  speed: 1,
  start: 0,
};

Counter.propTypes = {
  addSymbol: PropTypes.bool,
  color: PropTypes.string,
  dotSize: PropTypes.string,
  end: PropTypes.number,
  firstColor: PropTypes.string,
  firstFontSize: PropTypes.string,
  fontSize: PropTypes.string,
  incrementBy: PropTypes.number,
  speed: PropTypes.number,
  start: PropTypes.number,
};

export default Counter;
