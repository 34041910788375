import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';

import TypographyColorHighlight from '../../../../components/TypographyColorHighlight/TypographyColorHighlight';
import Counter from '../../../../components/Counter/Counter';

function useSizes() {
  const [windowSize, setWindowSize] = useState({
    baseFontSize: undefined,
    firstNumberFontSize: undefined,
    dotSize: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        baseFontSize: window.innerWidth >= 768 ? '360px' : '180px',
        firstNumberFontSize: window.innerWidth >= 768 ? '510px' : '290px',
        dotSize: window.innerWidth >= 768 ? '340px' : '170px',
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const GradientNumbers = () => {
  const { formatMessage } = useIntl();

  const sizes = useSizes();

  const counterParams = {
    start: 0,
    end: 8,
    speed: 120,
    incrementBy: 1,
    firstColor: 'linear-gradient(87.56deg, #9F2DEB 26.74%, #6200A3 97.23%)',
    color: '#820AD1',
    fontSize: sizes.baseFontSize,
    firstFontSize: sizes.firstNumberFontSize,
    addSymbol: false,
    dotSize: sizes.dotSize,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      tag="section"
    >
      <Counter {...counterParams} />
      <TypographyColorHighlight
        intlKey="HOME.NUMBERS.TITLE"
        variant="heading1"
        tag="h3"
        intlValues={{ br: <br /> }}
        textAlign="center"
        highlightText={formatMessage({ id: 'HOME.NUMBERS.TITLE.HIGHLIGHT' })}
        highlightColor="primary.default"
        marginTop={{ xs: '8x', lg: '12x' }}
        animate
      />

      <TypographyColorHighlight
        intlKey="HOME.NUMBERS.TITLE.PURPLE"
        variant="heading1"
        tag="h3"
        color="primary.default"
        marginBottom={{ xs: '12x', md: '16x', lg: '18x' }}
        animate
      />
    </Box>
  );
};

export default GradientNumbers;
